import { usePlayer } from "./hooks/usePlayer"
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Stack } from "@mui/material"
import { Fig } from "./components/Fig"
import YouTube from "@mui/icons-material/YouTube"

const things = [
  ["pretpompoen", "heksjeheerlijk"],
  ["gekgeestje", "monstertjemeer"],
  ["vrolijkevleermuis", "ketel"],
]

export default function App() {
  const { start, isStarted, toggleTrack, isTrackPlaying } = usePlayer()

  const getAsset = (track) => {
    return isTrackPlaying(track) ? `/assets/images/${track}.gif` : `/assets/images/${track}0.png`
  }

  return (
    <Box height={"100vh"} p={1}>
      {/*<Grid container spacing={2} justifyContent={"center"} alignItems={"center"} height={"100%"}>*/}
      {/*  {things.map((thing) => (*/}
      {/*    <Grid key={thing} item>*/}
      {/*      <Fig onClick={() => toggleTrack(thing)}>*/}
      {/*        <img src={getAsset(thing)} width={"100%"} />*/}
      {/*      </Fig>*/}
      {/*    </Grid>*/}
      {/*  ))}*/}
      {/*</Grid>*/}

      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100%"}>
        <Stack direction={"column"} spacing={{ xs: 1, md: 2 }}>
          {things.map((pair, index) => (
            <Stack key={index} direction={"row"} spacing={{ xs: 1, md: 2 }}>
              {pair.map((thing, index2) => (
                <Box key={`${index}-${index2}`}>
                  <Fig onClick={() => toggleTrack(thing)} isPlaying={isTrackPlaying(thing)}>
                    <img src={getAsset(thing)} width={"100%"} alt={thing} />
                  </Fig>
                </Box>
              ))}
            </Stack>
          ))}
          <Button
            color={"error"}
            variant={"contained"}
            startIcon={<YouTube />}
            sx={{ alignSelf: "center", textTransform: "none" }}
            component={"a"}
            href={"https://youtu.be/szU7Mc388sU?feature=shared"}
            target={"_blank"}
          >
            Herbekijk 2020
          </Button>
          <Button
            color={"error"}
            variant={"contained"}
            startIcon={<YouTube />}
            sx={{ alignSelf: "center", textTransform: "none" }}
            component={"a"}
            href={"https://youtu.be/hNWmedm4YGA?si=WnaMSlcU5Sioc2Wh"}
            target={"_blank"}
          >
            Herbekijk 2021
          </Button>
          <Button
            color={"error"}
            variant={"contained"}
            startIcon={<YouTube />}
            sx={{ alignSelf: "center", textTransform: "none" }}
            component={"a"}
            href={"https://youtu.be/ZL5MSBKNDYg?si=yisLlmvDnHiZDaqu"}
            target={"_blank"}
          >
            Herbekijk 2022
          </Button>
        </Stack>
      </Box>

      <Dialog open={!isStarted} onClose={start}>
        <DialogContent>Klik op een figuurtje om samen muziek te maken.</DialogContent>
        <DialogActions>
          <Button onClick={start}>Start</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
