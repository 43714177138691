import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { createTheme } from "@mui/material"

export const theme = createTheme({
  palette: {
    background: {
      default: "#626D5A",
    },
  },
})
