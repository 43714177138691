import { styled } from "@mui/material"

export const Fig = styled("div")(({isPlaying})=>({
  cursor: "pointer",
  borderRadius: "100%",
  background: "#3A3543",
  // background: "#E6E6E6",
  maxWidth: 200,
  aspectRatio: "1 / 1",
  // maxHeight: 200,
  // overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",

  ...isPlaying ? {"&:after": {
    content: '" "',
    border: "2px solid #3A3543",
    width: "100%",
    height: "100%",
    display: "block",
    position: "absolute",
    borderRadius: "100%",
    animation: "grow 2s infinite",
    boxShadow: "0px 0px 10px rgba(255,255,255,0.4)"
  }} : {},

  "@keyframes grow": {
    "0%": {
      transform: "scale(1)",
    },
    "100%": {
      transform: "scale(1.2)",
    },
  },
}))
