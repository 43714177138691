import React from "react"
import ReactDOM from "react-dom/client"
import { CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { theme } from "./ui/theme"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          "html, body": {
            height: "100vh",
            width: "100vw",
            overflow: "hidden",
          },
        }}
      />
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
