import { useEffect, useRef, useState } from "react"

const audioFiles = [
  "pretpompoen",
  "heksjeheerlijk",
  "gekgeestje",
  "vrolijkevleermuis",
  "monstertjemeer",
  "ketel",
] // Add all audio file names here

const GameState = {
  paused: "paused",
  started: "started",
}

export function usePlayer() {
  const audioContext = useRef()
  const [gameState, setGameState] = useState(GameState.paused)
  const [gainNodes, setGainNodes] = useState({})
  const [audios, setAudios] = useState({})
  const [playingList, setPlayingList] = useState([])

  const handleStart = () => {
    audioContext.current = new window.AudioContext()

    const data = {
      audios: {},
      gainNodes: {},
    }

    audioFiles.forEach((file) => {
      data.audios[file] = new Audio(`/assets/audio/${file}.mp3`)
      data.gainNodes[file] = null
    })

    for (const track in data.gainNodes) {
      data.gainNodes[track] = audioContext.current.createGain()
      data.gainNodes[track].connect(audioContext.current.destination)
    }

    audioContext.current.resume().then(() => {
      audioFiles.forEach((file) => {
        initTrack(data.audios[file], data.gainNodes[file])
      })
    })

    setGainNodes(data.gainNodes)
    setAudios(data.audios)
    setGameState(GameState.started)
  }

  const assertTrackExists = (track) => {
    if (!audioFiles.includes(track)) {
      console.error(`Track ${track} not found. Pick one of ${audioFiles.join(", ")}`)
      return false
    }
    return true
  }

  const isTrackPlaying = (track) => {
    if (!assertTrackExists(track)) {
      return false
    }
    return playingList.includes(track)
  }

  const initTrack = (audio, gainNode) => {
    try {
      const source = audioContext.current.createMediaElementSource(audio)
      source.connect(gainNode)
      gainNode.gain.value = 0
      audio.loop = true
      audio.play()
    } catch (e) {
      console.error("error playing track", e)
    }
  }

  const toggleTrack = (track) => {
    if (!assertTrackExists(track)) {
      return false
    }
    const isPlaying = isTrackPlaying(track)

    const gainNode = gainNodes[track]

    if (isPlaying) {
      gainNode.gain.value = 0
      setPlayingList((prev) => prev.filter((_track) => _track !== track))
    } else {
      gainNode.gain.value = 1
      setPlayingList((prev) => [...prev, track])
    }
  }

  return {
    start: handleStart,
    isStarted: gameState === "started",
    toggleTrack,
    isTrackPlaying,
  }
}
